import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import Nav from '../components/nav/nav'
import '../css/insight-post.scss'

import InsightLetter from '../images/icons/insight.svg'

import PostOne from '../images/insight/post-one.png'
import PostTwo from '../images/insight/post-two.png'
import PostThree from '../images/insight/post-three.png'
import PostFour from '../images/insight/post-four.png'
import PostFive from '../images/insight/post-five.png'
import ArrowRight from '../images/icons/arrow-right.svg'
import { Link } from 'gatsby'
import ClipLoader from "react-spinners/ClipLoader"
import Axios from 'axios'

const InsightsPosts = () => {


    const [post, setPost] = useState([])
    const [slicedPost, setSlicedPost] = useState([])
    const [loader, setLoader] = useState(false)

    const getPosts = async () => {
        setLoader(true)
        try {
            const results = await Axios.get('https://procentriciq.com/portal/api/posts')

            if (results.status === 200) {
                setPost(results.data.response.posts)
                setSlicedPost(results.data.response.posts.slice(2))
            }
            // console.log(results.data.response.posts)
        } catch (error) {
            console.error(error)
        }
        finally {
            setLoader(false)
        }
    }

    const dateFormatter = (date) => {
        const unix = Date.parse(date)
        const convertedDate = new Date(unix)
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let year = convertedDate.getFullYear();
        let month = months[convertedDate.getMonth()];
        let day = convertedDate.getDate();

        return day + ' ' + month + ', ' + year
    }

    useEffect(() => {
        getPosts()
    }, [])

    return (
        <Layout>
            <Nav
                isWhite={true}
            />

            <div className="insight-posts pt-5">
                <div className="position-relative">
                    {/* hero section */}
                    <div className="bg-insight py-5">
                        <div className="container py-3 my-4">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-1 px-0 mt-4">
                                            <div className="d-md-block d-none" style={{ borderBottom: '3px solid #ffffff' }}></div>
                                        </div>
                                        <div className="col-md-8">
                                            <h1 className="text-white font-weight-bold" style={{ lineHeight: '58px', fontSize: '3rem' }}>Insights</h1>

                                            <p className="text-white mb-0" style={{ lineHeight: '28px', fontSize: '1.5rem' }}>Our latest thinking on the issues that matter most in business and management.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 my-auto">

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="digital-letter-div mb-5">
                        <img src={InsightLetter} alt="" className="digital-img" />
                    </div>
                </div>

                <div className="my-5 py-5"></div>
                <div className="my-md-5 py-md-5"></div>



                <div className="container">

                    {loader &&
                        <div className="text-center">
                            <ClipLoader

                            />
                            <h5 className="pt-1">Loading...</h5>
                        </div>
                    }

                    {!loader && <>

                        {/* <div className="my-5 py-5">
                        <div className="row">
                            <div className="col-md-7">
                                <Link to="/individual-post"><div className="feature-post-bg">
                                    <img src={PostOne} alt="background" />
                                </div></Link>
                            </div>

                            <div className="col-md-5">
                                <div className="row">
                                    <div className="col-7">
                                        <p className="text-uppercase inner-page-breadcrumb mt-4"><span className="text-secondary mr-2">Digital</span>ofe ivharue</p>
                                    </div>

                                    <div className="col-5 text-right">
                                        <p className="text-uppercase mt-4 inner-page-breadcrumb">September 1, 2020</p>
                                    </div>
                                </div>


                                <Link to="/individual-post"><h4 className="cursor black-link-text" style={{lineHeight: '30px', fontWeight: '400'}}>But I must explain to you how all this mistaken idea of denouncing.</h4></Link>

                                <Link to="/individual-post"><p className="  cursor black-link-text mb-0 pt-4 my-auto">Read Article <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>
                            </div>
                        </div>
                    </div> */}


                        <div className="my-5 py-5">
                            <div className="row">
                                {
                                    post.length > 0 && <div className="col-md-8 mb-md-0 mb-5 pb-md-0 pb-4">
                                        <div className="post-bg">
                                            <img src={post.length > 0 ? post[0].image : ''} alt="background" />
                                        </div>

                                        <div className="row">
                                            <div className="col-7">
                                                <p className="text-uppercase mt-4 inner-page-breadcrumb"><span className="text-secondary mr-2">{post.length > 0 ? post[0].category.name : ''}</span>{post.length > 0 ? (post[0].user.first_name + ' ' + post[0].user.last_name) : ''}</p>
                                            </div>

                                            <div className="col-5 text-right">
                                                <p className="text-uppercase mt-4 inner-page-breadcrumb">{post.length > 0 ? dateFormatter(post[0].created_at) : ''}</p>
                                            </div>
                                        </div>

                                        <Link to={`/individual-post`} state={{ selectedPost: post[0] }}>
                                            <h4 className="cursor black-link-text" style={{ lineHeight: '30px', fontWeight: '400' }}>{post.length > 0 ? post[0].title : ''}</h4>
                                        </Link>

                                        <Link to={`/individual-post`} state={{ selectedPost: post[0] }}>
                                            <p className=" cursor black-link-text mb-0 pt-4 my-auto">Read Article <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p>
                                        </Link>
                                    </div>
                                }

                                {
                                    post.length > 0 && <div className="col-md-4 mb-md-0 mb-5 pb-md-0 pb-4">
                                        <div className="post-bg">
                                            <img src={post.length > 0 ? post[1].image : ''} alt="background" />
                                        </div>

                                        <div className="row">
                                            <div className="col-7">
                                                <p className="text-uppercase mt-4 inner-page-breadcrumb"><span className="text-secondary mr-2">{post.length > 0 ? post[1].category.name : ''}</span>{post.length > 0 ? (post[1].user.first_name + ' ' + post[1].user.last_name) : ''}</p>
                                            </div>

                                            <div className="col-5 text-right">
                                                <p className="text-uppercase mt-4 inner-page-breadcrumb">{post.length > 0 ? dateFormatter(post[1].created_at) : ''}</p>
                                            </div>
                                        </div>


                                        <Link to={`/individual-post`} state={{ selectedPost: post[1] }}>
                                            <h4 className="cursor black-link-text" style={{ lineHeight: '30px', fontWeight: '400' }}>{post.length > 0 ? post[1].title : ''}</h4>
                                        </Link>

                                        <Link to={`/individual-post`} state={{ selectedPost: post[1] }}>
                                            <p className=" cursor black-link-text mb-0 pt-4 my-auto">Read Article <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p>
                                        </Link>
                                    </div>
                                }
                            </div>


                            <div className="row mt-5">
                            {
                                    slicedPost && slicedPost.length > 0 && slicedPost.map((item, index) => <div key={index} className="col-md-4 mb-md-0 mb-5 pb-md-0 pb-4">
                                    <div className="post-bg">
                                        <img src={item.image} alt="background" />
                                    </div>

                                    <div className="row">
                                        <div className="col-7">
                                            <p className="text-uppercase mt-4 inner-page-breadcrumb"><span className="text-secondary mr-2">{item.category.name}</span>{item.user.first_name + ' ' + item.user.last_name}</p>
                                        </div>

                                        <div className="col-5 text-right">
                                            <p className="text-uppercase mt-4 inner-page-breadcrumb">{dateFormatter(item.created_at)}</p>
                                        </div>
                                    </div>


                                    <Link to={`/individual-post`} state={{ selectedPost: item }}>
                                        <h4 className="cursor black-link-text" style={{ lineHeight: '30px', fontWeight: '400' }}>{item.title}</h4>
                                    </Link>

                                    <Link to={`/individual-post`} state={{ selectedPost: item }}>
                                        <p className=" cursor black-link-text mb-0 pt-4 my-auto">Read Article <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p>
                                    </Link>
                                </div>
                                ) 
                                }
                            </div>
                        </div>

                        {/* <div className="my-5 py-5">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="feature-post-bg">
                                    <img src={PostOne} alt="background" />
                                </div>
                            </div>

                            <div className="col-md-5">
                                <div className="row">
                                    <div className="col-6">
                                        <p className="text-uppercase mt-4 inner-page-breadcrumb"><span className="text-secondary mr-2">Digital</span>ofe ivharue</p>
                                    </div>

                                    <div className="col-6 text-right">
                                        <p className="text-uppercase mt-4 inner-page-breadcrumb">September 1, 2020</p>
                                    </div>
                                </div>


                                <Link to="/individual-post"><h4 className="cursor black-link-text" style={{lineHeight: '30px', fontWeight: '400'}}>But I must explain to you how all this mistaken idea of denouncing.</h4></Link>

                                <Link to="/individual-post"><p className="  cursor black-link-text mb-0 pt-4 my-auto">Read Article <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>
                            </div>
                        </div>
                    </div> */}
                    </>
                    }

                    <div className="my-5 py-5"></div>
                </div>
            </div>
        </Layout>
    )
}

export default InsightsPosts
